.marginTop {
    margin-top: 180px;
}

.paddingTop {
    padding-top: 11px;
}

.paddingBottom {
    padding-bottom: 20px;
}

.imageWrapper {
    height: 700px;
    max-width: 1440px;
}

.backgroundImage {
    background-image: url('../../assets/images/src/circles.svg');
    background-repeat: no-repeat;
    background-position: center -50px;
    max-width: 800px;
    background-size: cover;
}

.circles {
    left: -230px;
    top: -300px;
}

@media screen and (max-width: 1023px) {
    .marginTop {
        margin-top: 80px;
    }
}

@media screen and (min-width: 720px) and (max-width: 1023px) {
    .marginTop {
        margin-top: 170px;
    }
    .backgroundImage {
        background-position: -150px -150px;
    }
}
