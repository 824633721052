.linkEffect {
    transition: all 1s ease-in-out;
    text-decoration: underline;
}

.linkEffect:hover {
    text-decoration: underline;
}

.copyright {
    display: flex;
    justify-content: space-between;
}

.privacy {
    color: #ffffff;
    text-decoration: none;
}

.divider {
    margin: 24px 0px;
    border: solid 1px rgba(255, 255, 255, 0.24);
}
