.carouselWidth {
    width: 500px;
}

.itemContainer {
    display: flex !important;
}

.avatar {
    height: 60px;
    width: 60px;
}

@media screen and (max-width: 767px) {
    .carouselWidth {
        width: 200px;
    }
}
