.maxWidth {
    max-width: 480px;
}
.input {
    width: 500px;
    padding: 12px 32px;
    height: 24px;
}

.button {
    padding: 8px 6px 8px 16px !important;
    top: 4px;
    right: 4px;
}

@media screen and (max-width: 720px) {
    .input {
        width: unset;
    }
}
