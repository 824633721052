.card {
    border-radius: 24px;
    max-width: 350px;
}

.productCard {
    border-radius: 24px;
    max-width: 270px;
}

@media screen and (min-width: 720px) and (max-width: 1365px) {
    .card {
        max-width: 270px;
    }
}

@media screen and (min-width: 1366px) {
    .card {
        max-width: 360px;
    }
}
