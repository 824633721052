@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap');

* {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

.productBackgroundImage {
    background-image: url('../assets/images/src/subpage-background.svg');
    background-size: cover;
}

.mc {
    max-width: 1440px;
}

.slick-prev::before,
slick-next::before {
    content: '';
}

.slick-prev,
.slick-prev:hover,
.slick-prev:focus {
    margin: 0px 32px 57px 16px !important;
    height: 32px;
    width: 32px;
    transform: translate(-20px, 0px) rotate(180deg);
    background-image: url('../assets/icons/src/arrow_circle.svg');
}
.slick-next,
.slick-next:hover,
.slick-next:focus {
    margin: 0px 32px 57px 16px !important;
    height: 32px;
    width: 32px;
    transform: translate(20px, 0px);
    background-image: url('../assets/icons/src/arrow_circle.svg');
}

@media screen and (min-width: 720px) {
    .slick-prev,
    .slick-prev:hover,
    .slick-prev:focus {
        margin: 43.2px 32px 57px 16px !important;
        height: 32px;
        width: 32px;
        transform: translate(-20px, -50px) rotate(180deg);
        background-image: url('../assets/icons/src/arrow_circle.svg');
    }
    .slick-next,
    .slick-next:hover,
    .slick-next:focus {
        margin: 43.2px 32px 57px 16px !important;        
        height: 32px;
        width: 32px;
        transform: translate(20px, -50px);
        background-image: url('../assets/icons/src/arrow_circle.svg');
    }
}
.slick-dots > li {
    margin: 0;
    width: 15px;
    height: 15px;
}
