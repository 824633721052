.menuHeight {
    transition: all 0.4s ease-in-out;
    height: 70px;
}

.menuExpanded {
    transition: all 0.4s ease-in-out;
    height: 100%;
}

.menuAndSubmenuExpanded {
    transition: all 0.4s ease-in-out;
    height: 100%;
}

.submenuCollapsed {
    height: 0px;
    transition: all 0.4s ease-in-out;
}

.submenuExpanded {
    height: 160px;
    transition: all 0.4s ease-in-out;
}

.fontSource {
    font-family: 'Source Sans Pro' !important;
}