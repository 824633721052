.navbarContent {
    transition: all 0.4s ease-in-out;
    height: 90px;
}

.navbarActive {
    height: 280px;
}

.submenuContent {
    transition: all 0.4s ease-in-out;
    height: 100%;
}

.hiddenSubmenuContent {
    transition: all 0.4s ease-in-out;
    height: 1px;
}

.languageMenuPosition {
    right: 190px;
    top: 65px;
}

.extendedItemMaxWidth {
    max-width: 150px;
}

.loginButton {
    width: 100px;
    outline: none;
}
