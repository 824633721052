.marginTop {
    margin-top: 130px;
}

.backgroundImage {
    background-image: url('../../assets/images/src/backgroundImage.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 24px !important;
    padding-bottom: 75px !important;
}

@media screen and (max-width: 1023px) {
    .marginTop {
        margin-top: 80px;
    }
}

@media screen and (min-width: 720px) and (max-width: 1023px) {
    .marginTop {
        margin-top: 170px;
    }
    .backgroundImage {
        background-position: -150px -150px;
    }
}
