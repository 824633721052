.notFound {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        padding: 12px;
    }

    .titleNotFound {
        font-family: SourceSansPro;
        font-size: 32px;
        font-weight: bold;
        color: #62708b;
        padding: 12px;
    }

    .textNotFound {
        font-family: SourceSansPro;
        font-size: 16px;
        color: #62708b;
        padding: 4px;
    }

    button {
        margin: 24px;
        padding: 9px 24px 11px;
        border-radius: 100px;
        border: solid 2px #004de8;
        background-color: #004de8;
        color: white;
        outline: none;
    }
}
