/** @define Grid; weak */

/**
 * Core grid component
 *
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */

/* Grid container
   ========================================================================== */

/**
 * All content must be contained within child elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 */

.Grid {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; /* 1 */
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin: 0; /* 1 */
    padding: 0; /* 1 */
}

/**
   * No explicit width by default. Rely on combining a cell with a dimension
   * utility or a component class that extends 'Grid'.
   *
   * 1. Set flex items to full width by default
   * 2. Fix issue where elements with overflow extend past the
   *    `.Grid > *` container - https://git.io/vw5oF
   */

.Grid > * {
    box-sizing: inherit;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%; /* 1 */
    min-width: 0; /* 2 */
}

/**
   * Modifier: center align all grid cells
   */

.Grid--alignCenter {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

/**
   * Modifier: right align all grid cells
   */

.Grid--alignRight {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

/**
   * Modifier: middle-align grid cells
   */

.Grid--alignMiddle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

/**
   * Modifier: bottom-align grid cells
   */

.Grid--alignBottom {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

/**
   * Modifier: allow cells to equal distribute width
   *
   * 1. Provide all values to avoid IE10 bug with shorthand flex
   *    http://git.io/vllC7
   *
   *    Use `0%` to avoid bug in IE10/11 with unitless flex basis
   *    http://git.io/vllWx
   */

.Grid--fill > * {
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%; /* 1 */
}

/**
   * Modifier: fit cells to their content
   */

.Grid--fit > * {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}

/**
   * Modifier: all cells match height of tallest cell in a row
   */

.Grid--equalHeight > * {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

/**
   * Modifier: gutters
   */

.Grid--withGutter {
    margin: 0 -6px;
}

.Grid--withGutter > * {
    padding: 0 6px;
}
@media (min-width: 1024px) {
    .Grid--withGutter {
        margin: 0 -12px;
    }

    .Grid--withGutter > * {
        padding: 0 12px;
    }
}

/**
   * @define utilities
   * Sizing utilities
   */

/* Proportional widths
     ========================================================================== */

/**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   *
   * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
   *    http://git.io/vllMD
   */

.u-size1of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 8.33333% !important;
}

.u-size1of10 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 10% !important;
}

.u-size1of8 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 12.5% !important;
}

.u-size1of6,
.u-size2of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 16.66667% !important;
}

.u-size1of5,
.u-size2of10 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 20% !important;
}

.u-size1of4,
.u-size2of8,
.u-size3of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 25% !important;
}

.u-size3of10 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 30% !important;
}

.u-size1of3,
.u-size2of6,
.u-size4of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 33.33333% !important;
}

.u-size3of8 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 37.5% !important;
}

.u-size2of5,
.u-size4of10 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 40% !important;
}

.u-size5of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 41.66667% !important;
}

.u-size1of2,
.u-size2of4,
.u-size3of6,
.u-size4of8,
.u-size5of10,
.u-size6of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 50% !important;
}

.u-size7of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 58.33333% !important;
}

.u-size3of5,
.u-size6of10 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 60% !important;
}

.u-size5of8 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 62.5% !important;
}

.u-size2of3,
.u-size4of6,
.u-size8of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 66.66667% !important;
}

.u-size7of10 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 70% !important;
}

.u-size3of4,
.u-size6of8,
.u-size9of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 75% !important;
}

.u-size4of5,
.u-size8of10 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 80% !important;
}

.u-size5of6,
.u-size10of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 83.33333% !important;
}

.u-size7of8 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 87.5% !important;
}

.u-size9of10 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 90% !important;
}

.u-size11of12 {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    width: 91.66667% !important;
}

/* Intrinsic widths
     ========================================================================== */

/**
   * Make an element shrink wrap its content.
   */

.u-sizeFit {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
}

/**
   * Make an element fill the remaining space.
   *
   * 1. Be explicit to work around IE10 bug with shorthand flex
   *    http://git.io/vllC7
   * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes
   *    http://git.io/vllMt
   */

.u-sizeFill {
    -webkit-flex: 1 1 0% !important;
    -ms-flex: 1 1 0% !important;
    flex: 1 1 0% !important; /* 1 */
    -webkit-flex-basis: 0% !important;
    -ms-flex-preferred-size: 0% !important;
    flex-basis: 0% !important; /* 2 */
}

/**
   * An alternative method to make an element fill the remaining space.
   * Distributes space based on the initial width and height of the element
   *
   * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
   */

.u-sizeFillAlt {
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
}

/**
   * Make an element the width of its parent.
   */

.u-sizeFull {
    width: 100% !important;
}

/**
   * @define utilities
   * Size: breakpoint 1 (small)
   */

@media screen and (min-width: 720px) {
    /* Proportional widths: breakpoint 1 (small)
       ======================================================================== */

    /**
     * Specify the proportional width of an object.
     * Intentional redundancy build into each set of unit classes.
     * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
     *
     * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
     *    http://git.io/vllMD
     */

    .u-sm-size1of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 8.33333% !important;
    }

    .u-sm-size1of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 10% !important;
    }

    .u-sm-size1of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 12.5% !important;
    }

    .u-sm-size1of6,
    .u-sm-size2of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 16.66667% !important;
    }

    .u-sm-size1of5,
    .u-sm-size2of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 20% !important;
    }

    .u-sm-size1of4,
    .u-sm-size2of8,
    .u-sm-size3of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 25% !important;
    }

    .u-sm-size3of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 30% !important;
    }

    .u-sm-size1of3,
    .u-sm-size2of6,
    .u-sm-size4of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 33.33333% !important;
    }

    .u-sm-size3of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 37.5% !important;
    }

    .u-sm-size2of5,
    .u-sm-size4of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 40% !important;
    }

    .u-sm-size5of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 41.66667% !important;
    }

    .u-sm-size1of2,
    .u-sm-size2of4,
    .u-sm-size3of6,
    .u-sm-size4of8,
    .u-sm-size5of10,
    .u-sm-size6of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 50% !important;
    }

    .u-sm-size7of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 58.33333% !important;
    }

    .u-sm-size3of5,
    .u-sm-size6of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 60% !important;
    }

    .u-sm-size5of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 62.5% !important;
    }

    .u-sm-size2of3,
    .u-sm-size4of6,
    .u-sm-size8of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 66.66667% !important;
    }

    .u-sm-size7of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 70% !important;
    }

    .u-sm-size3of4,
    .u-sm-size6of8,
    .u-sm-size9of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 75% !important;
    }

    .u-sm-size4of5,
    .u-sm-size8of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 80% !important;
    }

    .u-sm-size5of6,
    .u-sm-size10of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 83.33333% !important;
    }

    .u-sm-size7of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 87.5% !important;
    }

    .u-sm-size9of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 90% !important;
    }

    .u-sm-size11of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 91.66667% !important;
    }

    /* Intrinsic widths
       ======================================================================== */

    /**
     * Make an element shrink wrap its content.
     */

    .u-sm-sizeFit {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: auto !important;
    }

    /**
     * Make an element fill the remaining space.
     *
     * 1. Be explicit to work around IE10 bug with shorthand flex
     *    http://git.io/vllC7
     * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes
     *    http://git.io/vllMt
     */

    .u-sm-sizeFill {
        -webkit-flex: 1 1 0% !important;
        -ms-flex: 1 1 0% !important;
        flex: 1 1 0% !important; /* 1 */
        -webkit-flex-basis: 0% !important;
        -ms-flex-preferred-size: 0% !important;
        flex-basis: 0% !important; /* 2 */
    }

    /**
     * An alternative method to make an element fill the remaining space.
     * Distributes space based on the initial width and height of the element
     *
     * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
     */

    .u-sm-sizeFillAlt {
        -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
    }

    /**
     * Make an element the width of its parent.
     */

    .u-sm-sizeFull {
        width: 100% !important;
    }
}

/**
   * @define utilities
   * Size: breakpoint 2 (medium)
   */

@media screen and (min-width: 1024px) and (max-width: 1364px) {
    /* Proportional widths: breakpoint 2 (medium)
       ======================================================================== */

    /**
     * Specify the proportional width of an object.
     * Intentional redundancy build into each set of unit classes.
     * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
     *
     * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
     *    http://git.io/vllMD
     */

    /* postcss-bem-linter: ignore */

    .u-md-size1of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 8.33333% !important;
    }

    .u-md-size1of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 10% !important;
    }

    .u-md-size1of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 12.5% !important;
    }

    .u-md-size1of6,
    .u-md-size2of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 16.66667% !important;
    }

    .u-md-size1of5,
    .u-md-size2of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 20% !important;
    }

    .u-md-size1of4,
    .u-md-size2of8,
    .u-md-size3of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 25% !important;
    }

    .u-md-size3of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 30% !important;
    }

    .u-md-size1of3,
    .u-md-size2of6,
    .u-md-size4of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 33.33333% !important;
    }

    .u-md-size3of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 37.5% !important;
    }

    .u-md-size2of5,
    .u-md-size4of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 40% !important;
    }

    .u-md-size5of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 41.66667% !important;
    }

    .u-md-size1of2,
    .u-md-size2of4,
    .u-md-size3of6,
    .u-md-size4of8,
    .u-md-size5of10,
    .u-md-size6of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 50% !important;
    }

    .u-md-size7of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 58.33333% !important;
    }

    .u-md-size3of5,
    .u-md-size6of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 60% !important;
    }

    .u-md-size5of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 62.5% !important;
    }

    .u-md-size2of3,
    .u-md-size4of6,
    .u-md-size8of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 66.66667% !important;
    }

    .u-md-size7of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 70% !important;
    }

    .u-md-size3of4,
    .u-md-size6of8,
    .u-md-size9of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 75% !important;
    }

    .u-md-size4of5,
    .u-md-size8of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 80% !important;
    }

    .u-md-size5of6,
    .u-md-size10of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 83.33333% !important;
    }

    .u-md-size7of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 87.5% !important;
    }

    .u-md-size9of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 90% !important;
    }

    .u-md-size11of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 91.66667% !important;
    }

    /* Intrinsic widths
       ======================================================================== */

    /**
     * Make an element shrink wrap its content.
     */

    .u-md-sizeFit {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: auto !important;
    }

    /**
     * Make an element fill the remaining space.
     *
     * 1. Be explicit to work around IE10 bug with shorthand flex
     *    http://git.io/vllC7
     * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes
     *    http://git.io/vllMt
     */

    .u-md-sizeFill {
        -webkit-flex: 1 1 0% !important;
        -ms-flex: 1 1 0% !important;
        flex: 1 1 0% !important; /* 1 */
        -webkit-flex-basis: 0% !important;
        -ms-flex-preferred-size: 0% !important;
        flex-basis: 0% !important; /* 2 */
    }

    /**
     * An alternative method to make an element fill the remaining space.
     * Distributes space based on the initial width and height of the element
     *
     * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
     */

    .u-md-sizeFillAlt {
        -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
    }

    /**
     * Make an element the width of its parent.
     */

    .u-md-sizeFull {
        width: 100% !important;
    }
}

/**
   * @define utilities
   * Size: breakpoint 3 (large)
   */

@media screen and (min-width: 1365px) {
    /* Proportional widths: breakpoint 3 (large)
       ======================================================================== */

    /**
     * Specify the proportional width of an object.
     * Intentional redundancy build into each set of unit classes.
     * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
     *
     * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
     *    http://git.io/vllMD
     */

    .u-lg-size1of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 8.33333% !important;
    }

    .u-lg-size1of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 10% !important;
    }

    .u-lg-size1of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 12.5% !important;
    }

    .u-lg-size1of6,
    .u-lg-size2of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 16.66667% !important;
    }

    .u-lg-size1of5,
    .u-lg-size2of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 20% !important;
    }

    .u-lg-size1of4,
    .u-lg-size2of8,
    .u-lg-size3of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 25% !important;
    }

    .u-lg-size3of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 30% !important;
    }

    .u-lg-size1of3,
    .u-lg-size2of6,
    .u-lg-size4of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 33.33333% !important;
    }

    .u-lg-size3of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 37.5% !important;
    }

    .u-lg-size2of5,
    .u-lg-size4of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 40% !important;
    }

    .u-lg-size5of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 41.66667% !important;
    }

    .u-lg-size1of2,
    .u-lg-size2of4,
    .u-lg-size3of6,
    .u-lg-size4of8,
    .u-lg-size5of10,
    .u-lg-size6of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 50% !important;
    }

    .u-lg-size7of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 58.33333% !important;
    }

    .u-lg-size3of5,
    .u-lg-size6of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 60% !important;
    }

    .u-lg-size5of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 62.5% !important;
    }

    .u-lg-size2of3,
    .u-lg-size4of6,
    .u-lg-size8of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 66.66667% !important;
    }

    .u-lg-size7of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 70% !important;
    }

    .u-lg-size3of4,
    .u-lg-size6of8,
    .u-lg-size9of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 75% !important;
    }

    .u-lg-size4of5,
    .u-lg-size8of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 80% !important;
    }

    .u-lg-size5of6,
    .u-lg-size10of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 83.33333% !important;
    }

    .u-lg-size7of8 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 87.5% !important;
    }

    .u-lg-size9of10 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 90% !important;
    }

    .u-lg-size11of12 {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: 91.66667% !important;
    }

    /* Intrinsic widths
       ======================================================================== */

    /**
     * Make an element shrink wrap its content.
     */

    .u-lg-sizeFit {
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
        width: auto !important;
    }

    /**
     * Make an element fill the remaining space.
     *
     * 1. Be explicit to work around IE10 bug with shorthand flex
     *    http://git.io/vllC7
     * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes
     *    http://git.io/vllMt
     */

    .u-lg-sizeFill {
        -webkit-flex: 1 1 0% !important;
        -ms-flex: 1 1 0% !important;
        flex: 1 1 0% !important; /* 1 */
        -webkit-flex-basis: 0% !important;
        -ms-flex-preferred-size: 0% !important;
        flex-basis: 0% !important; /* 2 */
    }

    /**
     * An alternative method to make an element fill the remaining space.
     * Distributes space based on the initial width and height of the element
     *
     * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
     */

    .u-lg-sizeFillAlt {
        -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
        -webkit-flex-basis: auto !important;
        -ms-flex-preferred-size: auto !important;
        flex-basis: auto !important;
    }

    /**
     * Make an element the width of its parent.
     */

    .u-lg-sizeFull {
        width: 100% !important;
    }
}

/* :root {
    --Grid-gutterSize: 25px;
  } */
