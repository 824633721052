.outlinedButton {
    border: 2px solid #004de8;
    transition: all 0.4s ease-in-out;
}

.outlinedButton:hover {
    background-color: #004de8;
    color: white;
}

.buttonHover {
    transition: all 0.4s ease-in-out;
}

.buttonHover:hover {
    opacity: 0.9;
}
